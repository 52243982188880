@import '~@blueprintjs/core/lib/css/blueprint.css';
@import '~@blueprintjs/select/lib/css/blueprint-select.css';
@import '~@blueprintjs/icons/lib/css/blueprint-icons.css';
@import '~bootstrap/scss/bootstrap';

// ZCOOL+XiaoWei|Merriweather+Sans:400,400i|=PT+Sans:ital,wght@0,400;0,700;1,400;1,700|Mukta:wght@200;300;400;500;600;700;800|Josefin+Sans:ital,wght@0,400;0,500;0,700;1,300;1,400;1,600;1,700
@import url('https://fonts.googleapis.com/css?family=Prata|B612:ital,wght@0,400;0,700;1,400;1,700|Spectral:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,200;1,300;1,400;1,500;1,600;1,700;1,800&display=swap');

@import '~antd/dist/antd.css';

// @import 'path/to/@blueprintjs/core/lib/scss/variables';

$greyBackground: rgb(230, 216, 216);
$lightText: rgb(156, 142, 138);
$adminNavbarBackground: rgb(200, 207, 207);
$adminNavbarItemActiveBackground: rgb(68, 163, 163);
$adminNavbarItemHoverBackground: rgb(0, 1, 36);
$menuItemHoverBackground: rgb(176, 58, 46);

// body {
//   margin: 0;
//   font-family: 'B612', sans-serif;
//   -webkit-font-smoothing: antialiased;
//   -moz-osx-font-smoothing: grayscale;
// }

// h1 {
//   font-size: 24pt;
//   margin-bottom: 20px;
// }

// h2 {
//   font-size: 18pt;
//   margin-bottom: 20px;
//   color: rgb(92, 89, 89);
// }

// h3 {
//   font-size: 14pt;
//   margin-bottom: 20px;
// }

// label {
//   font-family: 'B612', sans-serif;
// }

// span {
//   font-family: 'B612', sans-serif;
// }

// a {
//   font-family: 'B612', sans-serif;
// }

// blueprint overrides -----------------------------------------------------------------------------

// .bp3-popover-arrow-border {
//   fill: white !important;
//   fill-opacity: 0.5 !important;
// }

// .bp3-popover-arrow-fill {
//   fill: black !important;
// }

// .bp3-menu-item:hover {
//   background-color: $adminNavbarItemHoverBackground;
//   background: $menuItemHoverBackground;
// }

// .bp3-label {
//   text-align: left;
// }

// .bp3-form-helper-text {
//   text-align: left;
// }

.bp3-tab {
  background-color: rebeccapurple;
}

.bp3-tab-indicator-wrapper .bp3-tab-indicator {
  background-color: #e07a5f;
  border-radius: 3px;
  padding: 10px;
  height: 40px;
}

.bp3-tab-list {
  padding: 10px;
  background-color: lightseagreen;
  justify-content: center;
}
